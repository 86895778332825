<template>
  <div class="activity-box">
    <el-dialog
      class="activity-dialog-box"
      width="40%"
      :close-on-click-modal="false"
      center
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
    >
      <svg class="close-icon" aria-hidden="true" @click="dialogVisible = false">
        <use xlink:href="#iconguanbi" />
      </svg>
      <h3 slot="title" class="title fontSize20 margin-t20">
        报名信息确认
      </h3>
      <div class="dialog-content">
        <p>开发者账号：{{userData.email}}</p>
        <p class="margin-t20" v-if="userData.companyName">企业名称：{{userData.companyName}}</p>
      </div>
      <el-form :model="form"  :rules="rules" ref="contactForm" label-width="100px">
        <el-form-item label="联系人姓名" prop="contactName">
          <el-input v-model="form.contactName" ></el-input>
        </el-form-item>
        <el-form-item label="联系人邮箱" prop="contactEmail">
          <el-input v-model="form.contactEmail"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="contactPhone">
          <el-input v-model="form.contactPhone"></el-input>
        </el-form-item>
        <el-form-item label="微信" prop="contactWechat">
          <el-input v-model="form.contactWechat"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="w200" @click="dialogVisible = false">取 消</el-button>
        <el-button class="w200" type="primary" @click="submit">立即报名</el-button>
      </span>
    </el-dialog>
    <div class="content-box">
        <el-button
          v-if="newsInfo.showEnableSignUp"
          class="w200 bgBlack white"
          @click="newsSignUp()"
        >报名</el-button>
        <el-button
          v-else
          class="w200 disabledBtn"
          disabled
        >已报名</el-button>
    </div>
  </div>
</template>
<script>
import { checkPhone, checkWeixin } from "@/utils/RegExp.js";
import { singleNewsSever } from "@/common/api-config";

export default {
  props:['newsInfo'],
  created() {
    this.newsId=this.$route.query.newsId;
  },
  computed: {
    userData() {
      return this.$store.getters["accountStore/userData"];//账户状态info
    },
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        contactName:'',//	必填	string	联系人姓名
        contactEmail:'',//	必填	string	联系人邮箱
        contactPhone:'',//	必填	string	联系人电话
        contactWechat:'',//	必填	string	联系人微信
      }, 
      rules: {
        contactName: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' },
        ],
        contactEmail: [
          { required: true, message: '请输入联系人邮箱', trigger: 'blur' },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { required: true, validator: checkPhone, trigger: "blur" },
        ],
        contactWechat: [
          { required: true, message: "请输入联系人微信", trigger: "blur" },
          { required: true, validator: checkWeixin, trigger: "blur" },
        ],
      },
      messageConfig:{
        'Token':{
          title: '请先登录账号',
          content: '您还未登录账号，请先登录您的账号，并确保您的账号资料已经完善且通过了联盟审核',
          cancelText: '取消',
          btnText: '立即登录',
        },
        '1':{
          title: '请完善账号信息',
          content: '您的账号资料还未完善，请先完善账户资料，并等待联盟审核',
          cancelText: '取消',
          btnText: '立即完善',
        },
        '3':{
          title: '提示',
          content: '您的账号已冻结',
          cancelText: '取消',
          btnText: '确定',
        },
        '4':{
          title: '账号审核中',
          content: '您的账号正在审核中，请等待联盟审核，审核通过后即可报名',
          btnText: '好的',
        },
        '5':{
          title: '提示',
          content: '您的账号信息审核不通过，请编辑后重新提交',
          btnText: '立即编辑',
        },
      },
    };
  },
  methods: {
    //报名
    newsSignUp(){
      if(!localStorage.getItem('Token')){
        this.$msgBox.alertMessageBox(this.messageConfig['Token']).then(async (data) => {
          if(data){
            this.$router.push({path: '/account/login', query: {'redirect': this.$router.currentRoute.fullPath}});//跳转登录页
          }
        }).catch((val) => {});
        return
      }
      this.$store.dispatch("accountStore/updateUserData", {}).then(res => {//去后台拿账户信息，更新本地状态
        //dataState:1-未完善 2-已完善 3-冻结 4-待审核 5-未通过
        switch (res.data.dataState) {
          case	1:
            this.$msgBox.alertMessageBox(this.messageConfig['1']).then((data) => {
              data && this.$router.push('/account/perfect/info');//跳转完善资料
            }).catch((val) => {});;
            break;
          case	2:
            this.form.contactName = res.data.contactName;
            this.form.contactEmail = res.data.contactEmail;
            this.form.contactPhone = res.data.contactPhone;
            this.form.contactWechat = res.data.weixin;
            this.dialogVisible = true ;
            break;
          case	3:
            this.$msgBox.alertMessageBox(this.messageConfig['3']);
            break;
          case	4:
            this.$msgBox.alertMessageBox(this.messageConfig['4']);
            break;
          case	5:
            this.$msgBox.alertMessageBox(this.messageConfig['5']).then((data) => {
              data && this.$router.push('/account/perfect/info');//跳转完善资料
            }).catch((val) => {});
            break;
          default:;
            break;
        }
      }).catch(()=>{});
    },
    //提交
    submit(){
      this.$refs.contactForm.validate((valid) => {
        if (valid) {
          singleNewsSever.newsSignUp({
            id:this.newsInfo.id,//	必填	int	资讯id
            contactEmail:this.form.contactEmail,//	必填	string	联系人邮箱
            contactName:this.form.contactName,//	必填	string	联系人姓名
            contactPhone:this.form.contactPhone,//	必填	string	联系人电话
            contactWechat:this.form.contactWechat,//	必填	string	联系人微信
          })
          .then((res) => {
            if (res.code == 0) {
              this.dialogVisible = false ;
              this.$parent.queryNewsInfo();//调父组件重新获取详情
              this.$msgBox.alertMessageBox({
                title: '报名成功',
                content: '您已报名成功，稍后我们的工作人员会联系您，沟通后续事宜，请耐心等候',
                btnText: '好的',
              }).then(async (data) => {
              }).catch((val) => {});
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style  lang="scss">
.alertMessageContent{
  .foot {
    .margin-r10{
      margin-right: 10px  !important;
    }
  }
}
</style>
<style scoped lang="scss">
.activity-dialog-box{
  /deep/ .el-dialog {
    border-radius: 10px;
  }
  /deep/ .el-dialog__body {
    padding-bottom: 0;
  }
  /deep/ .el-dialog__footer {
    padding-bottom: 40px;
  }

  .close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 9;
  }
  .title {
    position: relative;
    font-size: 18px;
    color: rgba(18, 18, 18, 1);
    text-align: center;
    &::after {
      content: "";
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      width: 73px;
      height: 6px;
      background: rgba(222, 208, 148, 1);
      border-radius: 3px;
    }
  }
  .dialog-content{
    margin-bottom: 20px;
    padding:30px;
    background: #FAFAFC;
    border: 1px solid #F2F2F2;
  }
  .dialog-footer {
    text-align: center;
  }
}
.activity-box {
  padding-top: 20px;
  padding-bottom: 32px;
  .content-box {
    padding:30px;
    background: #FAFAFC;
    border: 1px solid #F2F2F2;
    text-align: center;
    .w200{width: 200px !important;}
    .white{color: #fff!important;}
    .bgBlack{background-color: #000 !important;}
  }
}
</style>

