<template>
  <div class="voting-box">
    <div class="voting-participate-box ">
      <h3 class="title">{{newsInfo.vote.title}}</h3>
      <div class="participate-box">
        <p class="msg">{{newsInfo.vote.description}}</p>
        <div class="participate-list" v-if="!newsInfo.vote.hasVote">
          <el-radio-group v-if="newsInfo.vote.type==1" v-model="voteIds">
            <el-radio class="item" v-for="(item,index) in newsInfo.vote.item" :key="index" :label="item.id">{{item.title}}</el-radio>
          </el-radio-group>
          <el-checkbox-group v-if="newsInfo.vote.type==2" v-model="voteIds">
            <el-checkbox class="item" v-for="(item,index) in newsInfo.vote.item" :key="index" :label="item.id" :value="item.id" :data-value="item.id">{{item.title}}</el-checkbox>
          </el-checkbox-group>
          <div class="button-box">
            <el-button
              class="w200 bgBlack white"
              @click="onVote('form')"
            >投票</el-button>
          </div>
        </div>
        <div class="result-box" v-else>
          <div v-for="(item,index) in newsInfo.vote.item" :key="index">
            <p>{{item.title}}</p>
            <div class="result-list">
              <el-progress class="progress" :percentage="parseFloat(item.percentage)" color="#DED094"></el-progress>
              <p>{{item.num}}票</p>
            </div>
          </div>
          <div class="button-box">
            <el-button
              class="w200 disabledBtn"
              disabled
            >您已投票</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { singleNewsSever } from "@/common/api-config";

export default {
  props:['newsInfo'],
  created() {
    this.newsId=this.$route.query.newsId;
  },
  data() {
    return {
      percentage: 20,
      newsId:'',//资讯ID
      voteIds:[],//投票ids
    };
  },
  methods: {
    //投票
    onVote(){
      if(this.utils.isEmpty(this.voteIds)){
        singleNewsSever.newsVote({
          uuid: this.$cookies.get('uuid'),//	string	cookieId	唯一标识
          infoId: this.newsId,//	int	资讯id	*
          infoVoteId: this.newsInfo.extraId,//	int	投票id	*
          infoVoteItemId: this.voteIds.toString(),//	string	投票选项ids，多选逗号隔开	*
        })
        .then((res) => {
          if (res.code == 0) {
            this.$parent.queryNewsInfo();//调父组件重新获取详情
            this.$message.success('投票成功');
          } else {
            this.$message.error(res.message);
          }
        });
      }else{
        this.$message.error('请选中投票选项');
      }
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-progress-bar{
  width: 95%;
}
/deep/ .el-radio__inner{
  box-shadow: none !important;
  width: 12px !important;
  height: 12px !important;
}
.voting-box {
  padding-bottom: 32px;
  div,p { margin:0; padding:0;} 
  .w200{width: 200px !important;}
  .white{color: #fff!important;}
  .bgBlack{background-color: #000 !important;}
  .button-box{
    margin-top: 30px;
    text-align: center;
  }
  .voting-participate-box {
    border-top: 1px dashed  #DCDFE6;
    .title{
      margin: 20px 0;
      position: relative;
      padding-left: 20px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 6px;
        height: 100%;
        background: #ded094;
        border-radius: 3px;
      }
    }
    .participate-box{
      padding:30px;
      background: #FAFAFC;
      border: 1px solid #F2F2F2;
      .msg{
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      .participate-list{
        .item{
          display: block;
          margin-left: 10px;
          margin-top: 30px;
          /deep/ .el-radio__input{
            margin-right: 20px;
            transform: scale(1.4);
          }
          /deep/ .el-radio__label{
            font-size: 15px;
            color: #666666;
          }
        }
      }
    }
    .result-box{
      margin-top: 20px ;
      .result-list{
        display: flex;
        align-items: center;
        .progress{
          flex: 1;
          margin: 10px 0;
        }
      }
    }
  }
}
</style>

